import quizQ1 from 'assets/images/event/241216/quiz-q1.png';
import quizQ2 from 'assets/images/event/241216/quiz-q2.png';
import quizQ3 from 'assets/images/event/241216/quiz-q3.png';
import quizQ4 from 'assets/images/event/241216/quiz-q4.png';
import quizQ5 from 'assets/images/event/241216/quiz-q5.png';
import quizQ6 from 'assets/images/event/241216/quiz-q6.png';
import quizQ7 from 'assets/images/event/241216/quiz-q7.png';
import quizQ8 from 'assets/images/event/241216/quiz-q8.png';
import quizQ9 from 'assets/images/event/241216/quiz-q9.png';
import quizQ10 from 'assets/images/event/241216/quiz-q10.png';
import quizQ11 from 'assets/images/event/241216/quiz-q11.png';
import quizQ12 from 'assets/images/event/241216/quiz-q12.png';
import quizQ13 from 'assets/images/event/241216/quiz-q13.png';
import quizQ14 from 'assets/images/event/241216/quiz-q14.png';
import quizQ15 from 'assets/images/event/241216/quiz-q15.png';
import quizQ16 from 'assets/images/event/241216/quiz-q16.png';
import quizQ17 from 'assets/images/event/241216/quiz-q17.png';
import quizQ18 from 'assets/images/event/241216/quiz-q18.png';
import quizQ19 from 'assets/images/event/241216/quiz-q19.png';
import quizQ20 from 'assets/images/event/241216/quiz-q20.png';

const quizData = [

  {
    id: 1,
    title: `그림을 보고 빈칸에 <span>알맞은 말</span>을 고르세요.`,
    examples: ["장", "간", "점", "관"],
    answer: "관",
    src:quizQ1,
    gridType: 2
  },
  {
    id: 2,
    title: `그림을 보고 빈칸에 <span>알맞은 말</span>을 고르세요.`,
    examples: ["게", "배", "세", "개"],
    answer: "개",
    src:quizQ2,
    gridType: 2
  },
  {
    id: 3,
    title: `그림을 보고 <span>알맞은 단어</span>를 고르세요.`,
    examples: ["산", "계곡", "나무", "바다"],
    answer: "바다",
    src:quizQ3,
    gridType: 2
  },
  {
    id: 4,
    title: `그림을 보고 <span>알맞은 단어</span>를 고르세요.`,
    examples: ["빵집", "서점", "찜질방", "꽃집"],
    answer: "꽃집",
    src:quizQ4,
    gridType: 2
  },
  {
    id: 5,
    title: `그림을 보고 <span>알맞은 단어</span>를 고르세요.`,
    examples: ["노래하다", "청소하다", "세수하다", "일하다"],
    answer: "일하다",
    src:quizQ5,
    gridType: 2
  },
  {
    id: 6,
    title: `(<Fragment>&ensp;&ensp;&ensp;</Fragment>)에 들어갈 말로<br class="brMob"> 가장 <span>알맞은</span> 것을 고르십시오.`,
    examples: ["놉니다.", "잡니다.", "식사합니다.", "공부합니다."],
    answer: "공부합니다.",
    src:quizQ6,
    gridType: 2
  },
  {
    id: 7,
    title: `(<Fragment>&ensp;&ensp;&ensp;</Fragment>)에 들어갈 말로<br class="brMob"> 가장 <span>알맞은</span> 것을 고르십시오.`,
    src:quizQ7,
    examples: ["정말", "절대", "아주", "가끔"],
    answer: "가끔",
    gridType: 2
  },
  {
    id: 8,
    title: `(<Fragment>&ensp;&ensp;&ensp;</Fragment>)에 들어갈 말로<br class="brMob"> 가장 <span>알맞은</span> 것을 고르십시오.`,
    src:quizQ8,
    examples: ["에", "에서", "마다", "부터"],
    answer: "부터",
    gridType: 2
  },
  {
    id: 9,
    title: `(<Fragment>&ensp;&ensp;&ensp;</Fragment>)에 들어갈 말로<br class="brMob"> 가장 <span>알맞은</span> 것을 고르십시오.`,
    src:quizQ9,
    examples: ["도", "만", "을", "보다"],
    answer: "보다",
    gridType: 2
  },
  {
    id: 10,
    title: `(<Fragment>&ensp;&ensp;&ensp;</Fragment>)에 들어갈 말로<br class="brMob"> 가장 <span>알맞은</span> 것을 고르십시오.`,
    src:quizQ10,
    examples: ["그래서", "그러나", "하지만", "그리고"],
    answer: "그리고",
    gridType: 2
  },
  {
    id: 11,
    title: `다음은 <span>무엇에</span> 대한 내용입니까?`,
    src:quizQ11,
    examples: ["날씨", "가족", "채소", "과일"],
    answer: "과일",
    gridType: 2
  },
  {
    id: 12,
    title: `다음은 <span>무엇에</span> 대한 내용입니까?`,
    src:quizQ12,
    examples: ["직업", "날짜", "취미", "나이"],
    answer: "나이",
    gridType: 2
  },
  {
    id: 13,
    title: `다음은 <span>무엇에</span> 대한 내용입니까?`,
    src:quizQ13,
    examples: ["요리", "장소", "집안일", "건강"],
    answer: "건강",
    gridType: 2
  },
  {
    id: 14,
    title: `다음은 <span>무엇에</span> 대한 내용입니까?`,
    src:quizQ14,
    examples: ["옷", "음식", "취미", "직업"],
    answer: "옷",
    gridType: 2
  },
  {
    id: 15,
    title: `다음은 <span>무엇에</span> 대한 내용입니까?`,
    src:quizQ15,
    examples: ["공부", "병원", "세수", "쇼핑"],
    answer: "쇼핑",
    gridType: 2
  },
  {
    id: 16,
    title: `다음을 읽고 <span>이어지는 말</span>을 고르십시오.`,
    src:quizQ16,
    examples: ["네, 저는 수영을 잘해요.", "네, 수영을 할 수 없어요.", "아니요, 수영을 좋아해요.", "아니요, 수영을 할 줄 알아요."],
    answer: "네, 저는 수영을 잘해요.",
    gridType: 1
  },
  {
    id: 17,
    title: `다음을 읽고 <span>이어지는 말</span>을 고르십시오.`,
    src:quizQ17,
    examples: ["목요일에 가요.", "친구하고 같이 가요.", "지하철을 타고 가요.", "백화점에서 쇼핑해요."],
    answer: "지하철을 타고 가요.",
    gridType: 1
  },
  {
    id: 18,
    title: `다음을 읽고 내용이 <span>같은 것</span>을 고르십시오.`,
    src:quizQ18,
    examples: ["저만 요리를 합니다.", "주말에 영화관에 갑니다.", "동생은 집안일을 하지 않습니다.", "저는 주중에 학교에서 공부합니다."],
    answer: "주말에 영화관에 갑니다.",
    gridType: 1
  },
  {
    id: 19,
    title: `다음을 읽고 내용이 <span>같은 것</span>을 고르십시오.`,
    src:quizQ19,
    examples: ["저는 부산을 좋아하지 않습니다.", "저는 바다에서 수영을 했습니다.", "저는 가족하고 부산에 갔습니다.", "수영을 하기 전에 카페에 갔습니다."],
    answer: "저는 바다에서 수영을 했습니다.",
    gridType: 1
  },
  {
    id: 20,
    title: `다음을 읽고 중심 내용을 고르십시오.`,
    src:quizQ20,
    examples: ["요리 학원에 다니는 남자들이 많습니다.", "남자가 여자보다 대부분 요리를 잘합니다.", "요리에 관심이 있는 남자들이 많아졌습니다.", "취미로 요리를 배우는 사람들이 늘었습니다."],
    answer: "요리에 관심이 있는 남자들이 많아졌습니다.",
    gridType: 1
  },
];

export default quizData;