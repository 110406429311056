import styled from 'styled-components';
import bg from 'assets/images/event/230921/bg.png';
import bg2 from 'assets/images/event/230921/bg2.png';
import bg_pop from 'assets/images/event/241030/bg_pop.png';
import step3_bg from 'assets/images/event/230921/step3_bg.png';



const StyledEventPage = {
  EventWrap: styled.div`
    height:100%;
    min-height:100vh;
    background: #124e4f url(${bg}) center -88px no-repeat;
    width: 100%;
  `,
  Step1: styled.div`
    position:relative;
    overflow:hidden;
    width:100%;
    height: 772px;
    .step1-wrap{
      position: relative;
      z-index:2;
      background: url(${bg2}) center 460px no-repeat;
      background-size:contain;
      min-height:70vh;
      height:100%;
      width: 100%;
      display: flex;
      justify-content: top;
      align-items: center;
      gap:5rem;
      flex-direction: column;
      padding-top: 5.8rem;
      h1{
        width:53.2rem;
        height:23rem
      }
      .btn-takeTest{
        width:41.4rem;
        height: 40rem;
      }
   }
    &:after{
      content: "";
      z-index:1;
      background-color: #185b5b;
      width:100%;
      height:100%;
      top:460px;
      left:0;
      position:absolute
    }
    @media (max-width: 750px) {
      height:70vh;
      .step1-wrap{
        background: url(${bg2}) center 320px no-repeat;
        background-size: 200% auto;
        h1{
          width:43rem;
          height:auto;
        }
        .btn-takeTest{
          width:38rem;
          height:auto;
        }
      }
    }
    `,
   Step2: styled.div`
    min-height:70vh;
    height:100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:10rem 0 ;
    .step2-wrap{
      background: url(${bg_pop}) center center no-repeat;
      width:75rem;
      height:65rem;
      padding:2.7rem 0 2.7rem;
      .step2-con{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        .step2-con-head{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          h5{
            font-size :1.8rem;
            color: #9bdcba;
            font-family: "Nanum Myeongjo";
            border-bottom:1px solid #9bdcba;
            line-height: 2.1rem;
          }
          h3{
              position: relative;
              color: #fff;
              font-size: 2.8rem;
              font-weight: 500;
              text-align: center;
              margin-top:2rem;
              .brMob {
                  display: none;
              }
            span{
              position: relative;
              &::before{
                opacity: 0.2;
                background: #00ffff;
                width:100%;
                height:1rem;
                content:"";
                position: absolute;
                bottom:2px;
                left:0;
                z-index:0
              }
            }
          }
        }
        .step2-con-body {
            width: 55rem;
            height: 45rem;
            margin-top: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;;
            flex-direction: column;
            .img {
                background-color: #f5f5f5;
                width: 100%;
                height: 23rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 1.5rem;
                img {
                    max-width: 90%;
                    max-height: 100%;
                }
            }
            .examples {
                display: grid;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-top: 2rem;
                grid-template-columns: repeat(1, 1fr);
                gap: 1rem;
                button {
                    border: none;
                    background-color: #e8c8bd;
                    font-size: 2.8rem;
                    color: #000;
                    min-width: 47rem;
                    height: 5rem;
                    border-radius: 2.5rem;
                    font-weight: 700;
                    letter-spacing: -.1rem;
                    text-align: left;
                    padding: 0px 1rem 3px 2.8rem;
                    &:hover, &:active {
                        color: #fff;
                        background-color: #25676d;
                    }
                }
            }
            .examples2 {
                display: grid;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-top: 2rem;
                grid-template-columns: repeat(2, 1fr);
                gap: 1rem 2rem;
                button {
                    border: none;
                    background-color: #e8c8bd;
                    font-size: 3rem;
                    color: #000;
                    min-width: 22rem;
                    height: 5rem;
                    border-radius: 2.5rem;
                    font-weight: 700;
                    letter-spacing: -.1rem;
                    padding: 0 2.8rem 3px;
                    &:hover, &:active {
                        color: #fff;
                        background-color: #25676d;
                    }
                }
            }
        }
      }
      .step2-foot{
        display: flex;
        justify-content: flex-end;
        position: relative;
        width: 86.4%;
        margin: 1rem auto 0;
        button{
          font-size:1.6rem;;
          color:#fff;
          background: none;
          border:1px solid #fff;
          width:8rem;
          height:3rem;
          border-radius: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left:0rem;
          top:50%;
          transform: translateY(-50%);
        }
        .paging{
          font-size:1.6rem;;
          color:#fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    @media (max-width: 750px) {
      .step2-wrap{
          max-width: 54rem;
          width: 95%;
          height: 57rem;
        .step2-con { 
            height: 48.5rem;
            justify-content: flex-start;
            .step2-con-head h3 {
                margin-top: 1.5rem;
                .brMob {
                    display: block;
                }
            }
          .step2-con-body{
              width: 86.4%;
              height:auto;
              margin-top: 2rem;
              background-size: 100% auto;
              .img{
                height:17rem;
                padding: 1rem;
                &.type2 {
                    height: 14rem;
                }
              }
              .answerBox {
                  width: 100%;
                  .examples{
                      button{
                          min-width: auto;
                          width: 100%;
                          height:4rem;
                          font-size: 2.3rem;
                          padding: 0 1.3rem 1.1px;
                      }
                  }
              }
          }
          &.step2-con2{
            .step2-con-body{
              .img{
              img{
                height:100%;
                width:auto;
              }
            }
          }
        }
      }
    }
 `,
    Step3: styled.div`
     padding-bottom:15rem;
      .step3-wrap{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top:4.3rem;
        width:100%;
        .step3-head{
          display: flex;
        justify-content: center;
        flex-direction: column;
          h3{
            font-size:3.2rem;
            color:#fff;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              margin: 0 2rem
            }
          }
          .score-wrap{
            margin-top: 1.2rem;
            text-align:center;
            display: flex;
            justify-content: center;
            flex-direction: column;
            h5{
              font-size: 1.8rem;
              color:#fff;
            }
            h2{
              color:#fdb83f;
              font-weight:700;
              font-size:8rem;
              line-height: 1;
              margin-top: -9.5px;
            }
            p{
              color: #82b4ae;
              font-size: 1.8rem;
              margin-top: 1rem;
              line-height: 2.3rem;
            }
          }
        }
        .step3-body{
          width:75rem;
          height:95rem;
          background: url(${step3_bg});
          margin-top: 1.7rem;
          padding-top: 4rem;
          position:relative;
          .copy-modal{
            position: relative;
            position: absolute;
            left:50%;
            top:50%;
            transform: translate(-50%, -50%);
            width:35rem;
            height:23.8rem;
            background:#fff;
            border-radius:2rem ;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;;
            gap: 2rem;
            .close-btn{
              position: absolute;
              right:2rem;
              top:2rem;
              width:2rem;
              height:2rem;
              img{
                width:100%;
              }
            }
            h5{
              font-size:2rem;
              color:#000;
              border-bottom:1px solid #000;
            }
            h3{
              color:#000;
              font-size: 2.4rem;
              font-weight:700;
              text-align: center;
              line-height:2.9rem;
              span{color: #d85453;}
            }
            .check-btn{
              border-radius: 1.2rem;
              width:14rem;
              height:3.8rem;
              background-color: #255056;
              font-size:2rem;
              color:#fff;
              font-weight:700;
            }
          }
          .step3-table{
            display: flex;
              align-items: center;
              flex-direction: column;
            ul{
              border:6px solid rgba(0,0,0,0.2);
              display: flex;
              align-items: center;
              flex-direction: column;
              height:80rem;
              max-width:62rem;
              width:auto;
              li{
                display: flex;
                flex:1;
                align-items: center;
                justify-content: center;;
                width:100%;
                border-top:4px solid rgba(0,0,0,0.2);
                >span{
                  display: flex;
                  align-items: center;
                  justify-content: center;;
                  width:100%;
                  &.t-head{width:9rem;
                    color:#86b5bb;
                    font-size:1.8rem;
                    font-weight:700;
                  }
                  &.t-body{
                    flex:1;
                    border-left:4px solid rgba(0,0,0,0.2);
                    padding:0 4.4rem;
                    justify-content: flex-start;;
                    span{
                      font-weight:700;
                      font-size:2rem;
                      &.choice{
                        color:#fff;
                      }
                      &.wrong-answer{
                        margin-left:1.4rem;
                        color: #ed582a;
                        display: none;
                      }
                    }
                  }
                }
                &:first-child{
                  border-top:none;
                }
                &.wrong{
                  > span{
                    &.t-body{
                      span{
                    &.choice{
                          text-decoration:line-through #ed582a;
                        }
                        &.wrong-answer{display: flex;}
                      }
                    }
                  }
                }
              }
            }
          }
          .btn-wrap{
            display:flex;
            align-items: center;
            justify-content:center;
            gap:2rem;
            margin-top:2rem;
            button{
              width:19rem;
              height:6rem;
              border-radius: 3rem;
              font-size:2rem;
              color:#000;
              font-weight:700;
              border:none;
              &.btn-reset{ background:#e9f2a1;}
              &.btn-share{background:#9fedee;}
            }
          }
        }
        .step3-foot{
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .read-more{
            margin-top:2rem;
            font-size:2.6rem;
            color:#ffff00;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;;
            img{margin-bottom:1rem;}
            margin-bottom: 7rem;
          }
          h5{font-size:2.4rem;}
          h3{font-size:3.4rem; font-weight:700}
          ul{
            margin-top:5rem;
            display: flex;
            gap:1rem;
            align-items: center;
            justify-content:center;
            li{
              width:33.33%;
              img{
                width:100%;
                border-radius: 15px;
              }
            }
          }
          .btn-wrap{
            display: flex;
            align-items: center;
            justify-content:center;
            margin-top:5rem;
            button{
              width:28rem;
              height:7.7rem;
              display: flex;
              justify-content: center;
              align-items: center;
              border:none;
              color:#fff;
              font-size: 2.4rem;
              background: #d85453;
              border-radius: 4rem;
              font-weight:700;
            }
          }
        }

      }
      @media (max-width: 750px) {
        .step3-wrap{
          .step3-body{
            width: 56rem;
            .step3-table{
              ul{
                max-width: 51rem;
                  height: auto;
              }
            }
          }
          .step3-foot{
            padding: 0 2rem;
            h3 {
              font-size: 3rem;
            }
          }
        }
      }
    `
};

export default StyledEventPage;
